import { makeStyles } from '@material-ui/core/styles';
import Footerimg from './img/footer.PNG';
import '../index.css';

const useStyles = makeStyles({
    
    header: {
        width: '100%',
        minHeight:100
    },
})

export default function Footer(){
    const classes = useStyles();
    return(
    <div
    className={classes.header}>
    <img src={Footerimg} alt="Footer" className="footer"   />
    </div>
    )}