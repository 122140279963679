import React, {useState, useEffect, Component} from "react";
import {Button} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Logo from './img/Logo_lailaa.png';
import './css/Login.css';
import { useHistory } from "react-router-dom";
import qs from 'qs';
import '../index.css';

const useStyles = makeStyles({
  root: {
    textAlign:'center',
    marginTop:'10%',
  },
  card:{
   
    height:'35%',
    marginTop:'5%',
    marginLeft:'5%',
    marginRight:'5%',
    alignItems:'center',
    
  },
  img: {
    width:'25%',
  },
});



export default function LoginForm(){
  const [error]=useState();
  let isLoggedIn = false;
  let history = useHistory();
  
  //handle submit when logged in send to another site
  
  function loginUser() {
    const request = new Request('https://admin.perfectmadero.com/app/login', {
        method: 'POST',
        body: qs.stringify({
          username: username,
          password: password
        }),
        headers:{
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });

    return fetch(request).then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
        for (var pair of response.headers.entries()) {
          console.log(pair[0]+ ': '+ pair[1]);
        }
       const token = response.headers.get('Authorization');
       const identityRequest = new Request('https://admin.perfectmadero.com/app/identity', {
         method: 'GET',
         headers: new Headers({
           'Content-Type': 'application/json',
           authorization: token
         })
       });
       return fetch(identityRequest)
       .then(response => response.json())
       .then(json => {
         console.log("TUKAAAAAJ ------ Identity");
         if (response.status < 200 || response.status >= 300) {
           console.log(response.statusText);
           localStorage.removeItem('token');
           throw new Error(response.statusText);
         }
         if (json.identity) {
           console.log(json.identity.username);
           localStorage.setItem('id', json.identity.id ? json.identity.id.toString() : '0');
           localStorage.setItem('membership', json.identity.membershipList ? JSON.stringify(json.identity.membershipList) : '');
           localStorage.setItem('username', json.identity.username ? json.identity.username : '');
           localStorage.setItem('userFullName', json.identity.userFullName ? json.identity.userFullName : '');
           localStorage.getItem('membership');

           //koda za izpis
           var membership = JSON.parse(localStorage.getItem('membership'))
           localStorage.setItem('membershiParsed', membership[0].name);
           console.log(localStorage.getItem('membershiParsed'))
      }
         console.log("OK!");
         return Promise.resolve({
           userData: {
             id: json.identity.id,
            username: json.identity.username,
         }
         });
       });
      return response.json();
    }
    ).then(function(data) {
    const token = data['Authorization'];
    localStorage.setItem('Authorization', token);
    
    console.log(isLoggedIn);
    if(token == null && token ==''){
      isLoggedIn = false;  
      localStorage.setItem("isLoggedIn", isLoggedIn);
    }else{
      isLoggedIn = true;
      localStorage.setItem("isLoggedIn", isLoggedIn);
    }
    if(isLoggedIn === true){
    history.push('/Video-Platform');
    console.log(isLoggedIn);}
    else if (isLoggedIn === false || isLoggedIn === null)
    history.push('/');
    console.log(isLoggedIn);
    })
    .catch(error => { 
      alert('Username or password is incorrect!');
      console.log(error);
    })

    }
    
  
 const handleSubmit = e => {
    e.preventDefault();
    loginUser(
      {
      username,
      password
    }
    );
  }
  const forgotPassword = e =>{
    e.preventDefault();
    history.push('/Forgot-Password');
  }
  
  const classes = useStyles();
  const [username, onChangeUsername] = useState("");
  const [password, onChangePassword]= useState("");

    return (
      <div className={classes.root}>
      <img src={Logo} alt='Logo' className="logoImg"/>
  
      <form>
      <div className="login">
      <div
        style={{display: "flex",
        alignItems: "center",
        justifyContent: "center", marginLeft:'10%', marginRight:'10%',}}>
          <TextField
          id="outlined-basic"
          className="inputRounded"
          label="Email"
          variant="outlined"
          type="email"
          onChange={e => onChangeUsername(e.target.value)}
          style={{marginTop:100,  display:'block', width: 400, 
        }}
          value={username}
          required
          /></div>
          <div  style={{display: "flex",
        alignItems: "center",
        justifyContent: "center", marginLeft:'10%', marginRight:'10%',}}>
          <TextField 
          id="outlined-basic"
          className="inputRounded"
          label="Password"
          variant="outlined"
          type="password"
          onChange={e => onChangePassword(e.target.value)}
          value={password}
          style={{marginTop:20, height:"4%", display:'block', width: 400, }}
          required
          /></div>
          {/* <Button
          onClick={forgotPassword}
          className="Button-forgot"
          style={{display:"block", color:'Blue', marginTop:"2%", marginLeft:'80%', textTransform: 'none', textDecoration:'underline',}}
          >Forgot?
          </Button> */}
          <div  style={{display: "flex",
        alignItems: "center",
        justifyContent: "center",marginLeft:'10%', marginRight:'10%',}}>
          <Button 
          className="Button"
          variant="contained"
          color="primary"
          type="submit"
          style={{marginTop:"5%", width:"28%", height:"4%", backgroundColor:'black', borderRadius:'20px', textTransform: 'none', fontSize:"100%", width: 400,}}
          onClick={handleSubmit}
          >
          Login
          </Button></div></div>
          </form>
      
    </div>
    );
}
