import { makeStyles } from '@material-ui/core/styles';
import Vimeo from '@u-wave/react-vimeo';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    video:{
        height:'auto',
        width:'auto',
        marginLeft:'auto',
        marginRight:'auto',
    },
    videoCard:{
        marginTop: '50px',
        margin:'20px',
        border: "none",
        boxShadow: "none",
        backgroundColor: 'transparent',
    },
    header:{
        fontSize:22,
        marginBottom: 50,
        fontWeight:"bold",
        marginBottom: '50px',
        height: '54px',
        marginLeft: "40px"
    },

  })
function Videos(){
    const classes = useStyles();
    return(<>
  
  {localStorage.getItem('membershiParsed') == "Home" ? (
        <>
        <text className={classes.header}>
         Home Maderotherapy
            </text>
         <Grid container={12} >

            <Card className={classes.videoCard}>
                <CardContent>
                <Vimeo
                className={classes.video}
                    video="https://vimeo.com/841802011?share=copy"
                />
                <Typography variant="h5" component="h2">
                Teorija - maderoterapija
                </Typography>
                </CardContent>
                </Card>
                <Card className={classes.videoCard}>
                <CardContent>
                <Vimeo
                className={classes.video}
                    video="https://vimeo.com/842199194?share=copy"
                /> 
                <Typography variant="h5" component="h2">
                Domača maderoterapija
                </Typography>
                </CardContent>
                </Card>

            </Grid>
            </>
        
      ) : (
        localStorage.getItem('membershiParsed') == "Brazil" ? (
        <>
        <text className={classes.header}>
         Brazilian Maderotherapy
            </text>
         <Grid container={12} >
         
            <Card className={classes.videoCard}>
                <CardContent>
                <Vimeo
                className={classes.video}
                    video="https://vimeo.com/841962884?share=copy"
                />
                <Typography variant="h5" component="h2">
                Teorija - maderoterapija
                </Typography>
                </CardContent>
                </Card>
                <Card className={classes.videoCard}>
                <CardContent>
                <Vimeo
                className={classes.video}
                    video="https://vimeo.com/841962884?share=copy"
                /> 
                <Typography variant="h5" component="h2">
                Brazilska maderoterapija
                </Typography>
                </CardContent>
                </Card>

                
            </Grid>
            </>
        
      ) : (
              localStorage.getItem('membershiParsed') == "Klasik" ? (
              <>
              <text className={classes.header}>
               Classic Maderotherapy
                  </text>
               <Grid container={12} >

                  <Card className={classes.videoCard}>
                      <CardContent>
                      <Vimeo
                      className={classes.video}
                          video="https://vimeo.com/841962884?share=copy"
                      />
                      <Typography variant="h5" component="h2">
                      Teorija - maderoterapija
                      </Typography>
                      </CardContent>
                      </Card>
                      <Card className={classes.videoCard}>
                      <CardContent>
                      <Vimeo
                      className={classes.video}
                          video="https://vimeo.com/841819682?share=copy"
                      />
                      <Typography variant="h5" component="h2">
                      Brazilska maderoterapija
                      </Typography>
                      </CardContent>
                      </Card>
                      <Card className={classes.videoCard}>
                        <CardContent>
                        <Vimeo
                        className={classes.video}
                            video="https://vimeo.com/841802011?share=copy"
                        />
                        <Typography variant="h5" component="h2">
                        Brazilska maderoterapija
                        </Typography>
                        </CardContent>
                        </Card>
                  </Grid>
                  </>

            ) : (
        <>
         localStorage.getItem('membershiParsed') == "Perfectmadero" ? (
                      <>
                      <text className={classes.header}>
                       Perfect Madero
                          </text>
                       <Grid container={12} >

                          <Card className={classes.videoCard}>
                              <CardContent>
                              <Vimeo
                              className={classes.video}
                                  video="https://vimeo.com/841962884?share=copy"
                              />
                              <Typography variant="h5" component="h2">
                              Teorija - maderoterapija
                              </Typography>
                              </CardContent>
                              </Card>
                              <Card className={classes.videoCard}>
                              <CardContent>
                              <Vimeo
                              className={classes.video}
                                  video="https://vimeo.com/841819682?share=copy"
                              />
                              <Typography variant="h5" component="h2">
                              Brazilska maderoterapija
                              </Typography>
                              </CardContent>
                              </Card>
                              <Card className={classes.videoCard}>
                                <CardContent>
                                <Vimeo
                                className={classes.video}
                                    video="https://vimeo.com/841962884?share=copy"
                                />
                                <Typography variant="h5" component="h2">
                                Brazilska maderoterapija
                                </Typography>
                                </CardContent>
                                </Card>
                              <Card className={classes.videoCard}>
                                <CardContent>
                                <Vimeo
                                className={classes.video}
                                    video="https://vimeo.com/841959517?share=copy"
                                />
                                <Typography variant="h5" component="h2">
                                Brazilska maderoterapija
                                </Typography>
                                </CardContent>
                                </Card>

                          </Grid>
                          </>

                    ) : (
        <text className={classes.header}>
         Professional Maderotherapy
            </text>
         <Grid container={12} >
         
            <Card className={classes.videoCard}>
                <CardContent>
                <Vimeo
                className={classes.video}
                    video="https://vimeo.com/425866543"
                />
                <Typography variant="h5" component="h2">
                Teorija - maderoterapija
                </Typography>
                </CardContent>
                </Card>
                <Card className={classes.videoCard}>
                <CardContent>
                <Vimeo
                className={classes.video}
                    video="https://player.vimeo.com/video/461397952?badge=0&amp"
                /> 
                <Typography variant="h5" component="h2">
                Profesionalna maderoterapija
                </Typography>
                </CardContent>
                </Card>

                
            </Grid>
        </>
      ))
      )}
    </>
    )
}
export default Videos;