import React, {Component} from "react";
import LoginForm from "./LoginForm";

export default function Home(){

        return(
            <div className="Home">
                <LoginForm/>
            </div>
        );
}