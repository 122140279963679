import React from 'react';
import SideBar from './SideBar';
import Videos from './Videos';
import VideoPlatformHeader from './VideoPlatformHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Footer from './Footer';

const useStyles = makeStyles({
  sideBar:{
  },
  videoPlatform:{
  },
})

function VideoPlatform(){
  const classes = useStyles();
  return(
    <Grid container className={classes.body} >
        <VideoPlatformHeader/>
        <Grid item xl={2} 
        className={classes.sideBar}
        >
          <SideBar/>
        </Grid>
        <Grid item xl={10} 
        className={classes.videoPlatform}
        >
          <Videos/>
        </Grid>
        <Grid item xl={12} 
        >
          <Footer/>
        </Grid>
    </Grid>
  );
};
export default VideoPlatform;
