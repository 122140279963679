import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import bronze from './img/bronze.png';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router";
import { useEffect } from 'react';
import { Redirect, Route } from "react-router";


const useStyles = makeStyles({
    menu: {
        height: "100%",
        border: "none",
        boxShadow: "none",
        width: 200,
        fontSize: 'auto'

    },
    membership: {
        width: '100%',
        height: 'auto',
    },
    noDecoration: {
        color: 'black',
        textDecoration: 'none',
    },
})



export default function SideBar() {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    const classes = useStyles();
    let history = useHistory();
    useEffect(() => {
        if (isLoggedIn === false || isLoggedIn === null) {
            history.push('/');
            console.log(isLoggedIn);
            isLoggedIn = false;
        } else {
            history.push('/Video-Platform');
            console.log(isLoggedIn);
            isLoggedIn = true;
        }
    }, []);

    const onSubmitHandler = (e) => {
        e.preventDefault();
        history.push('/');
        // AsyncStorage.removeItem('id');
        // AsyncStorage.removeItem('userFullName');
        localStorage.removeItem('Authorization');
        localStorage.removeItem('isLoggedIn');
        // AsyncStorage.removeItem('username');
        // AsyncStorage.removeItem('userEmail');
        // AsyncStorage.removeItem('userFirstName');
        // AsyncStorage.removeItem('userLastName');
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.menu}>
                <MenuList id="menu-list-grow"
                    style={{ marginTop: '28px', }}
                >
                    <MenuItem
                       
                    >
                        <Button
                            onClick={onSubmitHandler}
                            style={{
                                color: "white", fontWeight: 600, fontSize: 14, backgroundColor: "#dfb695",
                                marginLeft: "10%", height:50, width: 100, 
                            }}
                        >
                            Log out
                        </Button>

                    </MenuItem>
                </MenuList>
            </Paper>
        </div>
    )
}