import { makeStyles } from '@material-ui/core/styles';
import Logo from './img/Logo_lailaa.png';
import Header from './img/header_perfectmadero.PNG';

const useStyles = makeStyles({
    header: {
        width: '100%',
        minHeight:100,
        
    },
    header2: {
        width: '100%',
        height: '100px',

    },
    headerImage: {
        width: '100%',
        height: '100px',

    },
    img: {
        marginLeft: '40px',
        marginBottom: '23px',
        height: '54px',
    },
    imgHeader: {
        width: '100%',
    },
    
})


export default function VideoPlatformHeader() {

    const classes = useStyles();
    return (
        <div >
            <div className={classes.header2}>
                <img src={Header} alt="Header" className="header" />
            </div>
            <div className={classes.header}>
                <img src={Logo} alt="Logo" className={classes.img} />
            </div>
        </div>
    )

}