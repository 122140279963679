import './App.css';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import VideoPlatform from './components/VideoPlatform';
import Home from "./components/Home";



export default function App(){
  // useEffect(() => {
  //   if(isLoggedIn === false){
  //     history.push('/');
  //   }else{
  //     history.push('/Video-Platform')
  //   }
  // });
    return(
      <div className="app">
        <BrowserRouter basename="/the-app">
          <Switch>
          {/*Home routes*/}
            <Route
              exact 
              path={"/"}
              component={Home}
              // render={props=>(
              //   <Home/>
              // )}
            />
            <Route
              exact
              path={"/Video-Platform"}
              component={VideoPlatform}
              // render={props=>(
              //   <VideoPlatform/>
              // )}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
}